import React, { Component } from 'react';

import logo from '../../resources/Logo.png';

import './style.css';

export default class HeaderIntro extends Component {
    render() {
        return (
            <>
                <div className='intro-text'>
                    <h3 className='trading-since'>Since 2018</h3>
                    <h1 className='company-intro'>Waxing &</h1>
                    <h1 className='company-intro'>Skin Care Specialist</h1>
                </div>
                <img className='logo' src={logo} alt='LogoImage'></img>
            </>
        );
    }
}