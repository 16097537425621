import anime from 'animejs';
import React, { Component } from 'react';

import './style.css';

export default class SplashScreen extends Component {
	componentDidMount() {
		var carousel = document.querySelector('.background');

		anime({
			targets: '.splash-screen',
			translateY: [0, 375],
			width: carousel.clientWidth,
			height: carousel.clientHeight,
			borderRadius: '25px',
			delay: 1500,
			duration: 1500,
			endDelay: 250,
			easing: 'easeInOutSine',
			complete: function () {
				anime({
					targets: '.splash-screen',
					opacity: 0,
					duration: 1000,
					complete: function () {
						document.querySelector('.splash-screen').style.display = 'none';
					},
				});
			},
		});
		anime({
			targets: '.splash-text',
			translateY: [-100, 0],
			opacity: 1,
			duration: 750,
			endDelay: 250,
			easing: 'easeInOutSine',
			complete: function () {
				anime({
					targets: '.splash-text',
					opacity: 0,
					duration: 750,
					easing: 'easeInOutSine',
				});
				anime({
					targets: ['.desktop-nav-item', '.book-button'],
					translateY: [-100, 0],
					delay: anime.stagger(100),
					duration: 1750,
					easing: 'easeInOutSine',
				});
				anime({
					targets: ['.trading-since', '.company-intro'],
					translateX: [-100, 0],
					delay: anime.stagger(200),
					duration: 1750,
					easing: 'easeInOutSine',
				});
			},
		});
	}
	render() {
		return (
			<>
				<div className="splash-screen">
					<h1 className="splash-text">Waxed By Molly</h1>
				</div>
			</>
		);
	}
}
