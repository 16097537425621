import $ from 'jquery';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import ServiceDataService from '../../services/ServiceDataService';

import './style.css';

export default class ServiceInfo extends Component {
	render() {
		const services = ServiceDataService.getAllServices();

		const searchParams = new URLSearchParams(document.location.search);
		var service = searchParams.get('service');

		var serviceSelected = $.grep(services, function (s) {
			return s.name === service;
		})[0];
		return (
			<>
				<div className="service-info-container">
					<Link className="back-button" to="/services">
						Back
					</Link>
					<div className="service-info-intro">
						<img
							className="s-image info-image"
							src={serviceSelected.image}
							alt="service-image"></img>
						<div className="service-info-text">
							<h1>{serviceSelected.name}</h1>
							<p className="service-info-description">
								{serviceSelected.description}
							</p>
							<div id="book-service-container">
								<Button
									className="book-service"
									onClick={() =>
										window.open(
											'https://www.fresha.com/book-now/waxed-by-molly-yy17hpc1/all-offer?pId=365110',
											'_blank'
										)
									}>
									Book Appointment
								</Button>
								<p id="book-service-price">{serviceSelected.price}</p>
							</div>
						</div>
					</div>
					<div className="service-after-care">
						<h1>After Care</h1>
					</div>
					<div className="after-care-info">
						<p className="text-start mb-5">{serviceSelected.afterCareIntro}</p>
						{serviceSelected.afterCareTips ? (
							<ListGroup as="ol" numbered>
								{serviceSelected.afterCareTips.map((tip, index) => (
									<ListGroup.Item
										as="li"
										className="after-care-item d-flex justify-content-between align-items-start"
										key={index}>
										<div className="ms-2 me-auto text-start">
											<div className="fw-bold text-start">{tip.tipTitle}</div>
											{tip.tip}
										</div>
									</ListGroup.Item>
								))}
							</ListGroup>
						) : null}
						{serviceSelected.bestResults ? (
							<p id="best-results-text">To get the best results...</p>
						) : null}
						{serviceSelected.bestResults ? (
							<ListGroup id="best-results-list" as="ol" numbered>
								{serviceSelected.bestResults.map((tip, index) => (
									<ListGroup.Item
										as="li"
										className="after-care-item d-flex justify-content-between align-items-start"
										key={index}>
										<div className="ms-2 me-auto text-start">
											<div className="fw-bold text-start">{tip.tip}</div>
										</div>
									</ListGroup.Item>
								))}
							</ListGroup>
						) : null}
					</div>
				</div>
			</>
		);
	}
}
