import { send } from 'emailjs-com';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function ContactForm() {
	const [inputs, setInputs] = useState({});
	const [show, setShow] = useState(false);

	const toggleShow = () => setShow(!show);

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const toSend = {
			from_name: inputs.name + ' ' + inputs.surname,
			to_name: 'Molly',
			message:
				inputs.message +
				'\n\n Contact Info:\n ' +
				inputs.name +
				' ' +
				inputs.surname +
				'\n' +
				inputs.email +
				'\n' +
				inputs.phonenumber,
			reply_to: inputs.email,
		};
		send('service_zafl103', 'template_11zqaap', toSend, 'e0f9sHiH9bhH7MtiP');
		setShow(true);
	};

	return (
		<>
			<ToastContainer className="p-5" position="top-start">
				<Toast show={show} bg="secondary" onClose={toggleShow}>
					<Toast.Header>
						<strong className="me-auto">Message Sent</strong>
						<small>Now</small>
					</Toast.Header>
					<Toast.Body>
						Thank you for sending a mesage we will be in touch shortly.
					</Toast.Body>
				</Toast>
			</ToastContainer>
			<Form className="mb-3" onSubmit={handleSubmit}>
				<div>
					<Form.Group className="mb-3" controlId="formName">
						<Form.Label>First name</Form.Label>
						<Form.Control
							type="name"
							placeholder="First name"
							required
							name="name"
							value={inputs.name || ''}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formName">
						<Form.Label>Last name</Form.Label>
						<Form.Control
							type="name"
							placeholder="Last name"
							name="surname"
							value={inputs.surname || ''}
							onChange={handleChange}
						/>
					</Form.Group>
				</div>
				<Form.Group className="mb-3" controlId="formEmail">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						required
						name="email"
						value={inputs.email || ''}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formNumber">
					<Form.Label>Phone number</Form.Label>
					<Form.Control
						type="number"
						placeholder="07545814227"
						name="phonenumber"
						value={inputs.phonenumber || ''}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formEmail">
					<Form.Label>Message</Form.Label>
					<Form.Control
						as="textarea"
						rows={4}
						required
						name="message"
						value={inputs.message || ''}
						onChange={handleChange}
					/>
				</Form.Group>
				<Button className="submit" type="submit">
					Send message
				</Button>
				<p className="parking-info">*Please park at the front of the salon</p>
			</Form>
		</>
	);
}
