import React from "react";
import Navbar from '../components/Navbar/Navbar';
import FooterBanner from "../components/Footer/FooterBanner";
import ServiceInfo from "../components/ServiceInfo/ServiceInfo";

const Service = () => {
  return (
    <div className="App">
      <Navbar /> 
      <div className="App-body">
        <ServiceInfo />
      </div>
      <footer>
        <div className="app-footer">
          <FooterBanner />
        </div>
      </footer>
    </div>
  );
};
  
export default Service;