import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { send } from 'emailjs-com';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import './style.css';

export default class FooterBanner extends Component {
	constructor() {
		super();
		this.state = { email: '' };
	}

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.state.email !== '') {
			const toSend = {
				from_name: 'Newsletter',
				to_name: 'Molly',
				reply_to: this.state.email,
			};
			send('service_zafl103', 'template_es7pzop', toSend, 'e0f9sHiH9bhH7MtiP');
		}
	};

	handleChange = (e) => {
		this.setState({ email: e.target.value });
	};

	render() {
		return (
			<>
				<div className="footer-banner">
					<div className="footer-container">
						<div className="newsletter">
							<h1 className="title">Newsletter</h1>
							<p className="newsletter-description">
								Sign up for latest news and offers
							</p>

							<Form className="newsletter-form" onSubmit={this.handleSubmit}>
								<Form.Control
									type="email"
									placeholder="Enter your email"
									value={this.state.email}
									onChange={this.handleChange}
								/>
								<Button
									className="subscribe-button"
									variant="primary"
									type="submit">
									Subscribe
								</Button>
							</Form>
						</div>
						<div className="get-in-touch">
							<h1 className="title">Get In Touch</h1>
							<br />
							<p>CHB Studios</p>
							<p>2 Fiddlers Ln</p>
							<p>Saughall</p>
							<p>Chester</p>
							<p>CH1 6DH</p>
						</div>
						<div className="contact">
							<div
								className="icon-group"
								onClick={() => window.open('tel:07545814227')}>
								<FontAwesomeIcon icon={faPhone} size="xl" />
								<p className="social-username">07545 814227</p>
							</div>
							<div
								className="icon-group"
								onClick={() => window.open('mailto:waxed.by.molly@gmail.com')}>
								<FontAwesomeIcon icon={faEnvelope} size="xl" />
								<p className="social-username">waxed.by.molly@gmail.com</p>
							</div>
							<div
								className="icon-group"
								onClick={() =>
									window.open(
										'https://www.instagram.com/waxed.by.molly/',
										'_blank'
									)
								}>
								<FontAwesomeIcon icon={faInstagram} size="xl" />
								<p className="social-username">waxed.by.molly</p>
							</div>
							<div
								className="icon-group"
								onClick={() =>
									window.open(
										'https://en-gb.facebook.com/WaxedByMolly/',
										'_blank'
									)
								}>
								<FontAwesomeIcon icon={faFacebook} size="xl" />
								<p className="social-username">Waxed by Molly</p>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
