import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from 'react-router-dom';

import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import Home from './Pages/Home';
import Service from './Pages/Service';
import Services from './Pages/Services';
import SplashScreen from './components/SplashScreen/SplashScreen';
import ScrollToTop from './components/Util/ScrollToTop';

import './App.scss';

function App() {
	const location = window.location.pathname;

	if (location === '/home' || location === '/') {
		var splashScreen = <SplashScreen />;
	}

	return (
		<>
			{splashScreen}
			<ScrollToTop />
			<Routes>
				{/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
				<Route path="/" element={<Home />} />

				{/* This route is for about component 
          with exact path "/home", in component 
          props we passes the imported component*/}
				<Route path="/home" element={<Home />} />

				{/* This route is for about component 
          with exact path "/services", in component 
          props we passes the imported component*/}
				<Route path="/services" element={<Services />} />

				{/* This route is for about component 
          with exact path "/service", in component 
          props we passes the imported component*/}
				<Route path="/service" element={<Service />} />

				{/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
				<Route path="/about" element={<About />} />

				{/* This route is for about component 
          with exact path "/contactus", in component 
          props we passes the imported component*/}
				<Route path="/contactus" element={<ContactUs />} />
			</Routes>
		</>
	);
}

export default App;
