import electrolysis from '../resources/Electrolysis.jpeg';
import brows from '../resources/Electrolysis.jpeg';
import derm from '../resources/Brows.jpeg';
import facials from '../resources/Facials.jpeg';
import wax from '../resources/Wax.jpeg';
import lashes from '../resources/Lashes.jpeg';

const ServiceDataService = {

    getAllServices() {
        return [{
            name: 'Waxing',
            shortDescription: 'Waxing is an effective and long-lasting solution for removing unwanted hair from any part of the body. I use specially formulated wax that is gentle on the skin and applied with specialist techniques. In addition to providing smooth, hair-free skin for weeks at a time, waxing can also help to reduce the appearance of ingrown hairs and exfoliate the skin, leaving it looking and feeling its best.',
            description: 'Waxing is an effective and long-lasting solution for removing unwanted hair from any part of the body. I use specially formulated wax that is gentle on the skin and applied with specialist techniques. In addition to providing smooth, hair-free skin for weeks at a time, waxing can also help to reduce the appearance of ingrown hairs and exfoliate the skin, leaving it looking and feeling its best.\n\nI use two types of wax "Hot" and "Strip" depending on what area of the body Im working on. for example hot wax is used for Hollywoods, bikinis, underarms and the face as these areas are more sensitive. Strip wax is used for legs and forearms as well as backs and chests in men as there is a larger surface area and the skin can take a little bit more!\n\nI understand your first wax can be daunting as it is, and then theres getting your kit off in front of someone new! My waxing room is forever free of judgement and Im always willing to answer any questions you have as well as build you up to become more confident with your appointments.',
            price: '£10 - £38',
            image: wax,
            afterCareIntro: 'Waxing is a popular method for removing unwanted hair, and it can leave your skin smooth and hair-free for weeks at a time. But its important to follow proper aftercare to ensure that your skin stays healthy and irritation-free. For 24 hours after yor wax...',
            afterCareTips: [{
                tipTitle: 'Avoid picking or scratching',
                tip: 'No touching or scratching the area.'
            },{
                tipTitle: 'Avoid hot water',
                tip: 'Not hot showers or baths (use warm water).'
            },{
                tipTitle: 'Avoid perfumes',
                tip: 'No perfume or deodorant.'
            },{
                tipTitle: 'Avoid swimming',
                tip: 'No swimming, hot tubs or saunas.'
            },{
                tipTitle: 'Avoid sun exposure',
                tip: 'No sunbeds or sunbathing.'
            },{
                tipTitle: 'Avoid certain soaps',
                tip: 'Avoid using harsh soaps.'
            },{
                tipTitle: 'Avoid tight clothing',
                tip: 'Avoid wearing tight clothing such as skinny jeans.'
            },{
                tipTitle: 'Avoid excessive exercise',
                tip: 'Avoid excessive sweating (good excuse to miss that spin class)'
            }
            ],
            bestResults: [{
                tip: 'Exfoliate 2-3 times a week (don’t over scrub).'
            },{
                tip: 'Moisturise everyday! So important.'
            },{
                tip: 'Ensure you book your next appointment, a regular waxing routine of 4-6 weeks will ensure you get the most of your wax.'
            }]
          },
        //   {
        //     name: 'Electrolysis',
        //     description: 'Electrolysis is a permanent hair removal method that uses a small, thin needle to deliver a precise electrical current to the hair follicle, destroying it and preventing new hair from growing. Our electrolysis service is performed by skilled professionals who use the latest techniques and equipment to provide a comfortable, effective solution for permanently removing unwanted hair from any part of the body. Contact us to learn more and see if electrolysis is the right choice for you.',
        //     price: '£10 - £38',
        //     image: electrolysis,
        //     afterCareIntro: 'Waxing is a popular method for removing unwanted hair, and it can leave your skin smooth and hair-free for weeks at a time. But its important to follow proper aftercare to ensure that your skin stays healthy and irritation-free.',
        //     afterCareTips: [{
        //         tipTitle: 'Avoid hot water',
        //         tip: 'Hot water can irritate the skin after waxing. Try to avoid hot showers or baths, and instead use lukewarm water.'
        //     }]
        //   },
          {
            name: 'Lashes',
            shortDescription: 'A Lash Lift is an enhancement of your natural lash. It lifts the lash from the root using perming solutions to curl the lash at the base resulting in longer thicker lashes effortlessly whilst lifting the appearance of the eye in the process. Who doesn’t want to wake up looking like they’ve actually got there 7-9 recommended hours every night.',
            description: 'A Lash Lift is an enhancement of your natural lash. It lifts the lash from the root using perming solutions to curl the lash at the base resulting in longer thicker lashes effortlessly whilst lifting the appearance of the eye in the process. Who doesnt want to wake up looking like theyve actually got there 7-9 recommended hours every night.\n\nEvery treatment includes a tint but if you want to use mascara for added thickness after the initial 24 hours you are more then welcome too. So put those damaging, outdated eyelash curler in the bin and get yourself booked in for this eye opening treatment.\n\nThis process takes around 45 minutes and will require a patch test at least 24 hours before treatment. Can be repeated every 6 weeks.',
            price: '£15 - £55',
            image: lashes,
            afterCareIntro: 'Just avoid the following for 24 hours, after that you can treat lashes as normal. Its recommended to use a lash serum for growth…',
            afterCareTips: [{
                tipTitle: 'Avoid hot water',
                tip: 'Water and steam (these can cause lashes to drop be careful of the dishwasher too).'
            },
            {
                tipTitle: 'Avoid mascara',
                tip: 'Mascaras can add weight to your eyelash extensions, which can make them lose their shape, droop down, or break off.'
            },
            {
                tipTitle: 'Avoid oil based products',
                tip: 'Avoid oil bases serums or moisturisers.'
            }]
          },{
            name: 'Brows',
            shortDescription: 'Getting your brows done can be the cheapest facelift you’ll ever come across and the best bang for your buck overall. Your brows have the ability to alter your look altogether which is why its so important to leave the tweezers in the bathroom and come to the salon to see a professional (I know it’s hard they literally talk to you sometimes but stay strong)',
            description: 'Getting your brows done can be the cheapest facelift you’ll ever come across and the best bang for your buck overall. Your brows have the ability to alter your look altogether which is why its so important to leave the tweezers in the bathroom and come to the salon to see a professional (I know it’s hard they literally talk to you sometimes but stay strong)\n\nThe treatments I provide are…\n\nStandard shaping - This is great if you have brows that are already the colour you want but you just need a reshape or a tidy!\n\nShape and Tint - This is for you if you need a reshape but also a colour correction and definition\n\nEyebrow Lamination - This treatment can be very correctional, it perms the hairs into the desired position for a fuller effect. They can be a little more dramatic if that’s how you like them or very subtle with your hairs straightened out for a polished finish. This treatment is inclusive of a tint and shape',
            price: '£12 - £40',
            image: brows,
            afterCareIntro: 'After your brow appointment it is important to stick to the after care guidelines, here are some tips to help you along the way:',
            afterCareTips: [{
                tipTitle: 'Avoid contact',
                tip: 'No touching or scratching the area'
            },{
                tipTitle: 'Avoid heat',
                tip: 'Not excess heat'
            },{
                tipTitle: 'Avoid water',
                tip: 'No swimming, hot tubs or saunas'
            },{
                tipTitle: 'Avoid sun exposure',
                tip: 'No sunbeds or sunbathing'
            },{
                tipTitle: 'Avoid harsh soaps',
                tip: 'Avoid using harsh soaps'
            },{
                tipTitle: 'Avoid excessive sweating',
                tip: 'Avoid excessive sweating (good excuse push that 5k back)'
            },{
                tipTitle: 'Avoid products',
                tip: 'If you’ve had lamination avoid getting brows wet or using oil based products for 24 hours'
            }],
            bestResults: [{
                tip: 'Return every 4 weeks (6 weeks for lamination)'
            },{
                tip: 'Try not to tweeze if possible'
            },{
                tip: 'Use a brow serum'
            }]
          },{
            name: 'Facials',
            shortDescription: 'Facials can be for relaxation or targeting specific concerns. With the rise of self-care and the emphasis on maintaining healthy, glowing skin, facials have become a go-to option for many people looking to rejuvenate and refresh their skin. Whether you’re dealing with dry skin, acne, fine lines, or other skin concerns I can help!',
            description: 'Facials can be for relaxation or targeting specific concerns. With the rise of self-care and the emphasis on maintaining healthy, glowing skin, facials have become a go-to option for many people looking to rejuvenate and refresh their skin. Whether you’re dealing with dry skin, acne, fine lines, or other skin concerns I can help!\n\nFacials I provide are…\n\nRelaxation - If you’ve had a long week and want to relax while focusing more on lymphatic drainage  and cleansing the skin this is the one!\n\nDeep cleansing - If you want to wipe away the dirt and grime of the week and work on any skin concerns you may have this is your treatment\n\nDermaplaning - Dermaplaning is an advanced skincare technique that helps to replenish the top layers of skin though a method of controlled surgical scraping. It’s a safe and simple procedure that removes 21 days worth of dead skin cells and vellus hairs. It also diminishes the appearance of fine lines.',
            price: '£25 - £60',
            image: facials,
            afterCareIntro: 'After your facial appointment it is important to stick to the after care guidelines, here are some tips to help you along the way:',
            afterCareTips: [{
                tipTitle: 'Avoid makeup',
                tip: 'Avoid makeup for at least 12 hours'
            },{
                tipTitle: 'Avoid touching or picking',
                tip: 'Avoid picking or excessively touching or using abrasive products for 72 hours'
            },{
                tipTitle: 'Avoid sunlight',
                tip: 'Avoid direct sunlight or heat for 48 hours'
            },{
                tipTitle: 'Avoid Retinol',
                tip: 'For Dermaplaning avoid retinol and Vitamin C products for 7 days'
            },{
                tipTitle: 'Avoid subeds & saunas',
                tip: 'No sunbeds, steam room or saunas for 24 hours'
            },{
                tipTitle: 'Avoid Chemicals ',
                tip: 'For Dermaplaning no chemical peels for 14 days'
            }
            ],
            bestResults: [{
                tip: 'Drink plenty of water'
            },{
                tip: 'Return every 4-6 weeks'
            }]
          }
        ];
    }

}

export default ServiceDataService;