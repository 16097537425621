import React from "react";
import Navbar from '../components/Navbar/Navbar';
import FooterBanner from "../components/Footer/FooterBanner";
import ServiceList from "../components/ServiceList/ServiceList";
  
const Services = () => {
  return (
    <div className="App">
      <Navbar /> 
      <div className="App-body">
        <ServiceList />
      </div>
      <footer>
        <div className="app-footer">
          <FooterBanner />
        </div>
      </footer>
    </div>
  );
};
  
export default Services;