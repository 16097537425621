import anime from 'animejs';
import React, { Component } from 'react';
import logo from '../../resources/AboutUsPic.jpg';

import './style.css';

export default class AboutUs extends Component {
	componentDidMount() {
		anime({
			targets: ['.about-us-container'],
			opacity: 1,
			duration: 1500,
			easing: 'easeInOutSine',
		});
		anime({
			targets: ['.about-title', '.about-text'],
			translateY: [-450, 0],
			duration: 1250,
			delay: 250,
			easing: 'easeInOutSine',
		});
		anime({
			targets: '.portrait',
			translateX: [350, 0],
			delay: 500,
			duration: 1000,
			opacity: 1,
			easing: 'easeInOutSine',
		});
	}
	render() {
		return (
			<>
				<div className="about-us-page">
					<div className="about-us-container">
						<div className="about-title">
							<h1>About Us</h1>
						</div>
						<div className="about-section">
							<div className="about-text">
								<p>
									Hello! I’m Molly and I’ve been making my clients as happy and
									hairless as possible since 2018. My main aim is to make sure
									every client not only gets the treatment results they are
									after but also feel comfortable in the process. Getting in the
									nud in front of a stranger and be daunting but I’m just here
									to let you know this is a non judgemental, safe space where
									everyone is welcome and no questions are off limits.
								</p>
								<p>
									I first took an interest in waxing when I was around 15 when
									buying a ‘microwavable at home wax kit’ from boots. Safe to
									say it took me a while to pick up another spatula after that
									horrific ordeal! After my waxing professional course I quickly
									realised how much I enjoyed it and loved the results and
									wanted to share them with others. Now I get to do what I love
									everyday giving you all the smooth skin you deserve!{' '}
								</p>
							</div>
							<img className="portrait" src={logo} alt="LogoImage"></img>
						</div>
					</div>
				</div>
			</>
		);
	}
}
