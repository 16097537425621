import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';

import './style.css';

export default class Navbar extends Component {
	toggleNav() {
		var navBar = document.querySelector('.navbar-ul-mobile');
		var s1 = document.querySelector('.strip-1');
		var s2 = document.querySelector('.strip-2');
		var s3 = document.querySelector('.strip-3');

		navBar.classList.toggle('toggle');
		s1.classList.toggle('strip1-clicked');
		s2.classList.toggle('strip2-clicked');
		s3.classList.toggle('strip3-clicked');
	}

	render() {
		return (
			<>
				<div className="nav-container-desktop">
					<div className="nav-items">
						<ul className="navbar-ul">
							<li className="desktop-nav-item">
								<NavLink
									to="/home"
									className={({ isActive }) => (isActive ? 'active-nav' : '')}>
									Home
								</NavLink>
							</li>
							<li className="desktop-nav-item">
								<NavLink
									to="/services"
									className={({ isActive }) => (isActive ? 'active-nav' : '')}>
									Services
								</NavLink>
							</li>
							<li className="desktop-nav-item">
								<NavLink
									to="/about"
									className={({ isActive }) => (isActive ? 'active-nav' : '')}>
									About
								</NavLink>
							</li>
							<li className="desktop-nav-item">
								<NavLink
									to="/contactus"
									className={({ isActive }) => (isActive ? 'active-nav' : '')}>
									Contact
								</NavLink>
							</li>
						</ul>
						<Button
							className="book-button"
							variant="primary"
							onClick={() =>
								window.open(
									'https://www.fresha.com/book-now/waxed-by-molly-yy17hpc1/all-offer?pId=365110',
									'_blank'
								)
							}>
							Book Now
						</Button>
					</div>
				</div>
				<div className="nav-containter-mobile">
					<div className="burger" onClick={this.toggleNav}>
						<div className="strip burger-strip">
							<div className="strip-1"></div>
							<div className="strip-2"></div>
							<div className="strip-3"></div>
						</div>
					</div>
					<Button
						className="book-button"
						variant="primary"
						onClick={() =>
							window.open(
								'https://www.fresha.com/book-now/waxed-by-molly-yy17hpc1/all-offer?pId=365110',
								'_blank'
							)
						}>
						Book Now
					</Button>
				</div>
				<ul className="navbar-ul-mobile">
					<li>
						<NavLink
							to="/home"
							className={({ isActive }) => (isActive ? 'active-nav' : '')}>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/services"
							className={({ isActive }) => (isActive ? 'active-nav' : '')}>
							Services
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/about"
							className={({ isActive }) => (isActive ? 'active-nav' : '')}>
							About
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contactus"
							className={({ isActive }) => (isActive ? 'active-nav' : '')}>
							Contact
						</NavLink>
					</li>
				</ul>
			</>
		);
	}
}
