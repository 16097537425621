import React from "react";
import Navbar from '../components/Navbar/Navbar';
import ContactUsPage from "../components/ContactUs/ContactUsPage";
import FooterBanner from "../components/Footer/FooterBanner";
  
const ContactUs = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="App-body">
        <ContactUsPage />
      </div>
      <footer>
        <div className="app-footer">
          <FooterBanner />
        </div>
      </footer>
    </div>
  );
};
  
export default ContactUs;