import React from "react";
import FooterBanner from "../components/Footer/FooterBanner";
import Navbar from '../components/Navbar/Navbar';
import AboutUs from "../components/AboutUs/AboutUs";
  
const About = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="App-body">
        <AboutUs />
      </div>
      <footer>
        <div className="app-footer">
          <FooterBanner />
        </div>
      </footer>
    </div>
  );
};
  
export default About;