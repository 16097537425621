import React, { Component } from 'react';

import { Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServiceDataService from '../../services/ServiceDataService';
import ProductsGrid from '../ProductsGrid/ProductsGrid';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

export default class HomeBody extends Component {
	render() {
		const services = ServiceDataService.getAllServices();
		return (
			<>
				<div className="background">
					<h2 className="servicesHeader">Our Services</h2>
					<Carousel className="carousel" indicators={false} interval={null}>
						{services.map((service, index) => (
							<Carousel.Item key={index}>
								<div className="service-container">
									<img className="service-image" src={service.image}></img>
									<div className="service-details">
										<p className="service-Name">{service.name}</p>
										<p className="service-price-info">{service.price}</p>
										<p className="service-description">
											{service.shortDescription}
										</p>
										<div className="service-actions">
											<Button
												className="service-book"
												variant="outline-secondary"
												onClick={() =>
													window.open(
														'https://www.fresha.com/book-now/waxed-by-molly-yy17hpc1/all-offer?pId=365110',
														'_blank'
													)
												}>
												Book Now
											</Button>
											<Button className="service-book" variant="secondary">
												<Link
													className="see-treatment-link"
													to={'/service?service=' + service.name}>
													See Treatment
												</Link>
											</Button>
										</div>
									</div>
								</div>
							</Carousel.Item>
						))}
					</Carousel>
				</div>
				<div className="products">
					<h2 className="productsHeader">Products In Salon</h2>
					<ProductsGrid />
				</div>
			</>
		);
	}
}
