import React from "react";
import HeaderIntro from '../components/HeaderIntro/HeaderIntro';
import Navbar from '../components/Navbar/Navbar';
import HomeBody from '../components/HomeBody/HomeBody';
import FooterBanner from "../components/Footer/FooterBanner";

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className='header-nav'>
          <Navbar />
        </div>
        <div className='header-intro'>
          <HeaderIntro />
        </div>
      </header>
      <div className="app-body">
        <HomeBody />
      </div>
      <footer>
        <div className="app-footer">
          <FooterBanner />
        </div>
      </footer>
    </div>
  );
};
  
export default Home;