import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons'

import ingrowGo from '../../resources/IngrowGo.jpg';
import bushBalm from '../../resources/bush-balm.png';

import './style.css';

function ProductsGrid() {
    const products = [{
        name: 'Ingrow Go',
        description: 'Skin Doctors Ingrow Go is a high-performance lotion that helps treat and prevent ingrown hairs quickly and effectively.',
        image: ingrowGo,
        price: '£13.95'
      },
      {
        name: 'Outback Organics Bush Balm',
        description: 'Instant soothing on delicate areas, this intensely moisturising vegan friendly balm quickly reduces redness and calms skin after hair removal.',
        image: bushBalm,
        price: '£6.50'
      }
    ];

  return (
    <Row xs={1} md={2} className="productGrid">
      {products.map((item,index) => (
        <Col  key={index}>
            <Card className='card-container'>
                <Card.Body>
                    <img className='product-img' variant="top" src={item.image} />
                    <div>
                      <Card.Title>{item.name}</Card.Title>
                      <Card.Text>{item.description}</Card.Text>
                      <div className='price-container'>
                        <span className='product-price'>{item.price}</span>
                        <span className='salon-tag'><FontAwesomeIcon className='basket-icon' icon={faBasketShopping} />In Salon</span>
                      </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
      ))}
    </Row>
  );
}

export default ProductsGrid;