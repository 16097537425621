import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import anime from 'animejs';

import ServiceDataService from '../../services/ServiceDataService';

import './style.css';

export default class ServiceList extends Component {
    componentDidMount() {
        anime({
            targets: '.services-container',
            translateY: [-200, 0],
            duration: 1500,
            opacity: 1,
            easing: 'easeInOutSine'
        })
        anime({
            targets: '.s-container',
            translateY: [750, 0],
            delay: anime.stagger(200),
            duration: 1650,
            easing: 'easeInOutSine'
        })
    }
    render() {
        const services = ServiceDataService.getAllServices();
        return (
            <>
                <div className='services-container'>
                    <div className='title-section'>
                        <h1>Our Services</h1>
                    </div>
                </div>
                <div className='services-list'>
                    <div className='s-container'>
                        <div className='s-details'>
                            <img className='s-image' src={services[0].image}></img>
                        </div>
                        <div className='s-info'>
                            <h1 className='background-list-text'>{services[0].name}</h1>
                            <h3>{services[0].name}</h3>
                            <p>{services[0].shortDescription}</p>
                            <div className='see-treatment-container'>
                                <Link className='treatment-link' to={"/service?service=" + services[0].name}>See Treatment</Link>
                                <p>{services[0].price}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='s-container'>
                        <div className='s-details'>
                            <img className='s-image' src={services[1].image}></img>
                        </div>
                        <div className='s-info'>
                        <h3>{services[1].name}</h3>
                            <p>{services[1].description}</p>
                            <Button><Link className='treatment-link' to="/service?service=Electrolysis">See Treatment</Link></Button>
                        </div>
                    </div> */}
                    <div className='s-container reverse'>
                        <div className='s-info'>
                            <h1 className='background-list-text'>{services[1].name}</h1>
                            <h3>{services[1].name}</h3>
                            <p>{services[1].shortDescription}</p>
                            <div className='see-treatment-container'>
                            <Link className='treatment-link' to={"/service?service=" + services[1].name}>See Treatment</Link>
                                <p>{services[1].price}</p>
                            </div> 
                       </div>
                        <div className='s-details'>
                            <img className='s-image' src={services[1].image}></img>
                        </div>
                    </div>
                    <div className='s-container'>
                        <div className='s-details'>
                            <img className='s-image' src={services[2].image}></img>
                        </div>
                        <div className='s-info'>
                            <h1 className='background-list-text'>{services[2].name}</h1>
                            <h3>{services[2].name}</h3>
                            <p>{services[2].shortDescription}</p>
                            <div className='see-treatment-container'>
                            <Link className='treatment-link' to={"/service?service=" + services[2].name}>See Treatment</Link>
                                <p>{services[2].price}</p>
                            </div>
                        </div>
                    </div>
                    <div className='s-container reverse'>
                        <div className='s-info'>
                            <h1 className='background-list-text'>{services[3].name}</h1>
                            <h3>{services[3].name}</h3>
                            <p>{services[3].shortDescription}</p>
                            <div className='see-treatment-container'>
                            <Link className='treatment-link' to={"/service?service=" + services[3].name}>See Treatment</Link>
                                <p>{services[3].price}</p>
                            </div>
                        </div>  
                        <div className='s-details'>
                            <img className='s-image' src={services[3].image}></img>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}