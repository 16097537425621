import React, { Component } from 'react';
import ContactForm from './ContactForm';

import './style.css';

export default class ContactUsPage extends Component {
	render() {
		return (
			<>
				<div className="contact-page">
					<div className="contact-form">
						<h1>Contact Us</h1>
						<p>We look forward to hearing from you!</p>
						<ContactForm />
					</div>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d149.27394008169586!2d-2.951781427378749!3d53.22890965515479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487adc5e1f0bd521%3A0x7002ee8098c7c6a9!2sC%20H%20B%20Studios!5e0!3m2!1sen!2suk!4v1722844925323!5m2!1sen!2suk"
						className="map"
						style={{ border: 0 }}
						aria-hidden="false"
						tabIndex="0"></iframe>
				</div>
			</>
		);
	}
}
